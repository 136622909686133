import React from 'react';
import ReactDOM from 'react-dom';
import autoClassmap from './auto.classmap';
import customClassmap from './custom.classmap';
import ClassMapLoader from '../loader/loader';

// import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
// import { connect } from 'react-redux';
// import thunkMiddleware from 'redux-thunk';
// import RavenMiddleware from 'redux-raven-middleware';
// import { createLogger } from 'redux-logger';
// import persistState from './jscream-redux-localstorage';

// import { sentry_dns } from './sentry.config';

const _react = window._react_front;
const loader = new ClassMapLoader([autoClassmap, customClassmap]);
const components = _react.components;

// let reducers = {};
// let storeInitialState = {};
// let actionCreators = {};
let props = {};
let persistedComponents = [];
for (let component in components)
  if (components.hasOwnProperty(component)) {
    let conf = components[component];
    let componentClass = loader.get(conf.class);

    // reducers[conf.name] = componentClass.getReducer()(conf.name);
    // actionCreators[conf.class] = componentClass.getActionCreators();
    // storeInitialState[conf.name] = conf.defaults;
    props[conf.name] = componentClass.prepareProps(conf.config, loader);
    if (conf.persisted) persistedComponents.push(conf.name);
  }

// store
// const loggerMiddleware = createLogger();
// let middleware = [];
// if (process.env.NODE_ENV !== 'production') middleware.push(thunkMiddleware, loggerMiddleware);
// else middleware.push(thunkMiddleware, RavenMiddleware(sentry_dns));

// const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

// const createPersistedStoreWithMiddleware = compose(persistState(persistedComponents, 'redux'))(
//   createStoreWithMiddleware
// );
// const store = createPersistedStoreWithMiddleware(combineReducers(reducers), storeInitialState);

for (let component in components)
  if (components.hasOwnProperty(component)) {
    const className = components[component].class;
    const componentName = components[component].name;
    let componentProps = props[componentName];

    // componentProps['store'] = store;

    const holder = document.getElementById(component);
    let componentFactory = loader.getReact(className);
    // let reduxConnectedComponent = connect(
    //   state => state[componentName],
    //   actionCreators[className](componentName)
    // )(componentFactory);

    ReactDOM.render(React.createElement(componentFactory, componentProps), holder);
  }
