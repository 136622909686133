var _get = require('lodash/get');
var _isArray = require('lodash/isArray');
var _forOwn = require('lodash/forOwn');
var React = require('react');

function ClassMapLoader(classmap) {
  if (_isArray(classmap)) {
    var mergedClassmap = {};
    classmap.forEach(function(classes) {
      _forOwn(classes, function(className, key) {
        mergedClassmap[key] = className;
      });
    });

    this.classmap = mergedClassmap;
  } else this.classmap = classmap;
}

ClassMapLoader.prototype.get = function(key) {
  var object = _get(this.classmap, key, null);
  if (object === null) throw new Error('Object "' + key + '" is not present in classmap');
  else return object;
};

ClassMapLoader.prototype.getReact = function(key) {
  var reactClass = this.get(key);
  return React.createFactory(reactClass);
};

module.exports = ClassMapLoader;
