import React from 'react';
import TimeAgo from 'react-timeago';
import czechStrings from 'react-timeago/lib/language-strings/cs';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import InputField from './InputField';
import { Can } from '@casl/react';

import { replaceEmojis } from './helpers';

const formatter = buildFormatter(czechStrings);
const moment = require('moment');

class Comment extends React.Component {
  state = {
    reply: false,
    value: '',
    typing: false,
    open: true
  };

  componentDidMount() {
    const { socket } = this.props;
    socket.connection.on('typing', data => {
      if (data.source && data.source === this.props.comment._id) this.setState({ typing: true });
    });
    socket.connection.on('stopped', data => {
      if (data && data.source && data.source === this.props.comment._id) this.setState({ typing: false });
    });
  }

  onClick = value => {
    if (this.state.value !== '') {
      this.props.onComment(value, this.props.comment._id);
      this.setState({ reply: false, value: '' });
    }
  };

  onToggle = () => {
    if (this.state.reply && this.state.value !== '') {
      if (window.confirm('Máte rozepsaný komentář. Chcete jej smazat?')) {
        this.setState({ value: '', reply: false });
        this.props.socket.connection.emit('stopped', {
          source: this.props.comment._id,
          object: this.props.id,
          type: this.props.type
        });
      }
    } else this.setState({ value: '', reply: !this.state.reply });
  };

  onChange = (value, plain) => {
    if (plain.trim() !== '') {
      this.props.socket.connection.emit('typing', {
        source: this.props.comment._id,
        object: this.props.id,
        type: this.props.type
      });
      this.setState({ value });
    } else
      this.props.socket.connection.emit('stopped', {
        source: this.props.comment._id,
        object: this.props.id,
        type: this.props.type
      });
  };

  render() {
    const { comment, users, lastSeen } = this.props;
    const { reply, open } = this.state;
    const alteredComment = replaceEmojis(comment.comment);
    let classes = [];
    comment.tags.map(tag => classes.push(tag.class));
    return (
      <div>
        <div
          className={`comment indent-${this.props.indent || 0} ${classes.join(' ')} ${
            moment(lastSeen).isBefore(comment.createdAt, 'second') ? 'unread' : ''
          }`}>
          <div className='field'>
            <div className='user-image'>
              {users[comment.user] && users[comment.user].image ? (
                <img alt={users[comment.user].name} src={users[comment.user].image} />
              ) : (
                <span className={`bg-image ${users[comment.user] ? users[comment.user].class : ''}`}>
                  {users[comment.user] ? (
                    <span>{users[comment.user].initials}</span>
                  ) : (
                    <i className='fa fa-user' />
                  )}
                </span>
              )}
            </div>
            <div className='comment-content'>
              <div className='comment-text' dangerouslySetInnerHTML={{ __html: alteredComment }} />
              <div className='comment-head'>
                <div className='comment-first'>
                  <input
                    checked={checked}
                    name='read'
                    onChange={() => {
                      this.props.socket.connection.emit('tag', {
                        id: comment._id,
                        tag: { name: 'checked', checked: !checked }
                      });
                    }}
                    type='checkbox'
                  />
                  <span className='comment-name'>
                    {users[comment.user] ? users[comment.user].name : null}
                  </span>
                </div>
                {this.state.typing ? (
                  <div className='typing'>
                    <div className='dot-typing' /> Někdo odpovídá!
                  </div>
                ) : null}
                <span className='comment-next'>
                  <span className='date'>
                    <time>{moment(comment.createdAt).format('DD.MM.YYYY HH:mm')} </time>
                    <span className='time-before'>
                      (<TimeAgo date={comment.createdAt} formatter={formatter} />)
                    </span>
                  </span>
                  <span className='separator'>|</span>
                  {comment.replies.length !== 0 ? (
                    <a className='reply-toggle' onClick={() => this.setState({ open: !open })} type='button'>
                      {open ? (
                        <span>
                          <i className='far fa-chevron-up' /> ({comment.replies.length})
                        </span>
                      ) : (
                        <span>
                          <i className='far fa-chevron-down' /> ({comment.replies.length})
                        </span>
                      )}
                      <span className='separator'>|</span>
                    </a>
                  ) : null}
                  <Can I='delete' a='Post' ability={this.props.ability}>
                    <a
                      onClick={() => {
                        if (confirm('Opravdu chete smazat komentář?')) this.props.onDelete(comment._id);
                      }}
                      type='button'>
                      <i className='fal fa-trash-alt' />
                    </a>
                    <span className='separator'>|</span>
                  </Can>
                  <a
                    onClick={() => {
                      this.onToggle();
                    }}
                    type='button'>
                    {reply ? 'zrušit' : 'odpovědět'}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.state.reply ? (
          <div className={`indent-${this.props.indent + 1 || 1} reply-container`}>
            <div className='reply field'>
              <InputField onChange={this.onChange} post={this.state.value} />
            </div>
            <div className='submit'>
              <button
                disabled={!this.state.value && this.state.value === ''}
                onClick={() => {
                  this.onClick(this.state.value);
                }}>
                odpovědět
              </button>
            </div>
          </div>
        ) : null}
        {comment.replies && this.state.open
          ? comment.replies.map((child, i) => {
            return (
              <Comment
                {...this.props}
                comment={child}
                indent={this.props.indent ? this.props.indent + 1 : 1}
                key={i}
              />
            );
          })
          : null}
      </div>
    );
  }
}

export default Comment;
