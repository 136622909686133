import React from "react";
import io from "socket.io-client";
import { hot } from "react-hot-loader";

class NotificationContainer extends React.PureComponent {
  state = {
    messages: [],
    show: false,
  };

  socket = null;

  componentDidMount() {
    const messages = Array.isArray(JSON.parse(localStorage.getItem("notif-react-state")))
      ? JSON.parse(localStorage.getItem("notif-react-state"))
      : [];
    this.setState({ messages });
    this.socket = io(this.props.nodeUrl || "http://localhost:7001");
    this.socket
      .emit("authenticate", {
        token: this.props.token,
      })
      .on("authenticated", () => {
        console.log("authorized");
        this.socket.emit("join room", { user: this.props.user });
        this.socket.on("notification", payload => {
          const messages = [...this.state.messages, payload.message];
          localStorage.setItem("notif-react-state", JSON.stringify(messages));
          this.setState({ messages });
        });
      })
      .on("unauthorized", function() {
        console.log("Unauthorized");
      });
  }

  render() {
    const { messages, show } = this.state;
    const listStyle = {
      position: "absolute",
      right: 0,
      top: 64,
      background: "white",
      border: "1px solid #96ac23",
      borderRadius: 5,
      width: 350,
      fontSize: "12pt",
      lineHeight: "22px",
      padding: 12,
      textTransform: "none",
    };
    return (
      <div className="notification-client" style={{ position: "relative", marginLeft: 10 }}>
        <i
          onClick={messages.length !== 0 ? () => this.setState({ show: !this.state.show }) : () => {}}
          className={`${show ? "far" : "fas"} fa-bell`}
          style={{ fontSize: "16pt", cursor: "pointer" }}
        />
        {messages.length !== 0 ? <span className="homepage-header">{messages.length}</span> : null}
        {show ? (
          <div style={listStyle}>
            {messages.map((msg, i) => (
              <div
                className="message"
                key={i}
                dangerouslySetInnerHTML={{ __html: msg }}
                style={{ padding: 5, borderBottom: i === messages.length - 1 ? "none" : "1px solid #FEF3E1" }}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  static prepareProps(config) {
    return config;
  }
}

export default hot(module)(NotificationContainer);
