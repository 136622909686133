import React from 'react';
import Comments from './../../_devel_components/Comments/Comments';
import { hot } from 'react-hot-loader';

class CommentsContainer extends React.Component {
  render() {
    if (!this.props.allowed)
      return (
        <div id='flash'>
          <div className='item error'>Diskuze byly pro tento příspěvek zakázány.</div>
        </div>
      );
    else if (!this.props.user)
      return (
        <div id='flash'>
          <div className='item error'>Diskuze jsou pouze pro přihlášené uživatele.</div>
        </div>
      );
    else if (!this.props.token)
      return (
        <div id='flash'>
          <div className='item error'>Nelze získat autorizační token.</div>
        </div>
      );
    else return <Comments {...this.props} />;
  }

  static prepareProps(config) {
    return config;
  }
}

export default hot(module)(CommentsContainer);
