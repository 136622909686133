import React from "react";
import ReactQuill from "react-quill";
import Emoji from "./Emoji";
import emojis from "./emojis";

import "react-quill/dist/quill.snow.css";

class InputField extends React.PureComponent {
  state = {
    emoticons: false,
  };

  render() {
    return (
      <div className="field-container">
        <ReactQuill
          onChange={(value, delta, source, editor) => {
            this.props.onChange(value, editor.getText());
          }}
          value={this.props.post}
        />
        <div className="emoji-picker">
          {this.state.emoticons ? (
            <div>
              {Object.keys(emojis).map(emo => {
                return (
                  <Emoji
                    code={emojis[emo].code}
                    key={emojis[emo].code}
                    onClick={code => {
                      let val = this.textarea.value;
                      val = val + String.fromCodePoint(code);
                      this.props.onChange(val);
                    }}
                    svg={emojis[emo].svg}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default InputField;
