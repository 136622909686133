import React from 'react';
import io from 'socket.io-client';
import Comment from './Comment';
import InputField from './InputField';
import ability from './ability';

import axios from 'axios';
import './dot-typing.less';

class Comments extends React.Component {
  state = {
    comments: [],
    post: '',
    loading: true,
    unauthenticated: true,
    typing: false,

    users: {},
    error: false,

    lastSeen: null,

    unread: 0
  };

  socket = {
    connection: null,
    connect: function(app) {
      if (this.connection) {
        this.connection.destroy();
        delete this.connection;
        this.connection = null;
      }
      this.connection = io(app.props.nodeBaseUrl);
      this.connection.once('connect', () => {
        this.connection
          .emit('authenticate', {
            token: app.props.token
          })
          .on('authenticated', () => {
            app.setState({ loading: false, unauthenticated: false });
            this.connection.emit('join room', { object: app.props.id, type: app.props.type });
            this.connection.on('userLog', payload => {
              const lastSeen = payload.log;
              app.setState({ lastSeen });
            });
            this.connection.on('comments', payload => {
              const comments = payload.comments;
              app.setState({ comments });
              app.getUserData(comments);
            });
            this.connection.on('newComment', comment => {
              let comments = [...app.state.comments];
              if (comment.parent !== null) {
                const findComm = (comms, id) => {
                  comms.map(comm => {
                    if (comm._id === id) comm.replies.push(comment);
                    else if (comm.replies) findComm(comm.replies, id);
                  });
                  return comms;
                };
                findComm(comments, comment.parent);
                if (!Object.keys(app.state.users).includes('' + comment.user))
                  axios.post(app.props.userListUrl, ['' + comment.user]).then(res => {
                    let copy = { ...app.state.users };
                    copy['' + comment.user] = res.data['' + comment.user];
                    app.setState({ users: copy });
                  });
                app.setState({ comments });
              } else {
                comments.push(comment);
                if (!app.state.users['' + comment.user])
                  axios.post(app.props.userListUrl, ['' + comment.user]).then(res => {
                    let copy = { ...app.state.users };
                    copy['' + comment.user] = res.data['' + comment.user];
                    app.setState({ users: copy });
                  });
                app.setState({ comments });
              }
            });
            this.connection.on('udpatedComment', comment => {
              let comments = [...this.state.comments];
              const findComm = (comms, id) => {
                comms.map(comm => {
                  if (comm._id === id) {
                    comm = { ...comm, ...comment };
                    return comm;
                  } else if (comm.replies) comms = findComm(comm.replies, id);
                });
                return comms;
              };
              findComm(comments, comment._id);
              app.setState({ comments });
            });
            this.connection.on('typing', data => {
              if (data && !data.source) app.setState({ typing: true });
            });
            this.connection.on('stopped', data => {
              if (data && !data.source) app.setState({ typing: false });
            });
            this.connection.on('err', msg => {
              app.setState({ error: msg });
            });
            this.connection
              .on('disconnect', () => {
                app.setState({ unauthenticated: true });
              })
              .on('reconnect', () => {
                app.setState({ unauthenticated: false });
                this.connect(app);
              });
          })
          .on('unauthorized', () => {
            app.setState({ loading: false, unauthenticated: true });
          });
      });
    }
  };

  componentDidMount() {
    axios
      .get(`${this.props.nodeBaseUrl}/auth/user`, { headers: { 'x-access-token': this.props.token } })
      .then(res => {
        if (res.data.rules) ability.update(res.data.rules);
      })
      .catch(error => {
        console.error(error);
      });
    this.socket.connect(this);
  }

  componentWillUnmount() {
    this.socket.connection.emit('leave room', { object: this.props.id, type: this.props.type });
  }

  getUserData = comments => {
    const getUserIds = comm => {
      let ids = [];
      comm.map(c => {
        if (!ids.includes('' + c.user)) ids.push('' + c.user);
        if (c.replies) ids.concat(getUserIds(c.replies));
      });
      return ids;
    };
    const ids = getUserIds(comments);
    if (!ids.includes('' + this.props.user)) ids.push('' + this.props.user);
    axios
      .post(this.props.userListUrl, ids)
      .then(res => {
        this.setState({ users: res.data });
      })
      .catch(function(error) {
        console.error(error);
      });
  };

  onChange = (post, plain) => {
    if (plain.trim() !== '') {
      this.socket.connection.emit('typing', { source: false, object: this.props.id, type: this.props.type });
      this.setState({ post });
    } else
      this.socket.connection.emit('stopped', { source: false, object: this.props.id, type: this.props.type });
  };

  onSubmit = () => {
    if (this.state.post.trim() !== '') {
      this.socket.connection.emit('post', {
        comment: this.state.post,
        object: this.props.id,
        user: this.props.user,
        type: this.props.type
      });
      this.setState({ post: '' });
    }
  };

  onComment = (comment, parent) => {
    this.socket.connection.emit('reply', {
      comment,
      object: this.props.id,
      parent,
      user: this.props.user,
      type: this.props.type
    });
  };

  onDelete = id => {
    this.socket.connection.emit('delete', { id, object: this.props.id, type: this.props.type });
  };

  addUnread = () => {
    this.setState({ unread: this.state.unread + 1 });
  };

  render() {
    const { comments, unauthenticated, error, loading } = this.state;
    return (
      <div className='comments-client'>
        {/* <h2 className='unread-count'>Nezobrazené: {this.state.unread}</h2> */}
        {this.state.typing ? (
          <div>
            <div className='dot-typing' /> Někdo píše komentář
          </div>
        ) : null}
        {error ? (
          <div id='flash'>
            <div className='item error'>{error}</div>
            <i className='fal fa-times' onClick={() => this.setState({ error: false })} />
          </div>
        ) : null}
        {!loading ? (
          <div>
            {unauthenticated ? (
              <div className='overlay'>
                <h1 className='warning'>Chyba připojení na server</h1>
              </div>
            ) : null}
            {this.props.fieldPosition === 'top' ? (
              <div className='fields'>
                <div className='field'>
                  <InputField onChange={this.onChange} post={this.state.post} />
                </div>
                <div className='submit'>
                  <button
                    disabled={!this.state.post && this.state.post === ''}
                    onClick={() => this.onSubmit()}>
                    Vložit
                  </button>
                </div>
              </div>
            ) : null}
            <div className='comments'>
              {Array.isArray(comments) && comments.length !== 0 ? (
                comments.map((comment, i) => {
                  return (
                    <Comment
                      {...this.props}
                      ability={ability}
                      addUnread={this.addUnread}
                      comment={comment}
                      key={i}
                      lastSeen={this.state.lastSeen}
                      onComment={this.onComment}
                      onDelete={this.onDelete}
                      socket={this.socket}
                      users={this.state.users}
                    />
                  );
                })
              ) : (
                <p>Zatím nejsou žádné komentáře. Buďte první!</p>
              )}
            </div>
            {this.props.fieldPosition === 'bottom' || !this.props.fieldPosition ? (
              <div className='fields'>
                <div className='field'>
                  <InputField onChange={this.onChange} post={this.state.post} />
                </div>
                <div className='submit'>
                  <button
                    disabled={!this.state.post && this.state.post.trim() === ''}
                    onClick={() => this.onSubmit()}>
                    Vložit
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div>NAČÍTÁM</div>
        )}
      </div>
    );
  }
}

export default Comments;
