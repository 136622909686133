import React from "react";

class Emoji extends React.PureComponent {
  render() {
    const { onClick, svg, code } = this.props;
    return <i dangerouslySetInnerHTML={{ __html: svg }} onClick={() => onClick(code)} />;
  }
}

export default Emoji;
